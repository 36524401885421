export default{
  locale_al                                               : () => {
    return {selectAll                                     : '(Zgjidh te gjitha)',
      selectAllSearchResults                              : '(Zgjidh te gjitha nga rezultati i kerkimit)',
      searchOoo                                           : 'Kerko...',
      blanks                                              : '(Bosh)',
      noMatches                                           : 'Nuk ka perputhje',

      // Number Filter & Text Filter
      filterOoo                                           : 'Filtro...',
      equals                                              : 'Baraz',
      notEqual                                            : 'Jo Baraz',
      blank                                               : 'Bosh',
      notBlank                                            : 'Jo bosh',
      empty                                               : 'Zgjedh Nje',

      // Number Filter
      lessThan                                            : 'Me e vogel se',
      greaterThan                                         : 'Me e madhe se',
      lessThanOrEqual                                     : 'Me e vogel se ose baraz',
      greaterThanOrEqual                                  : 'Me e madhe se ose baraz',
      inRange                                             : 'Ne Interval',
      inRangeStart                                        : 'nga',
      inRangeEnd                                          : 'te',

      // Text Filter
      contains                                            : 'Permban',
      notContains                                         : 'Nuk permban',
      startsWith                                          : 'Fillon me',
      endsWith                                            : 'Mbaron me',

      // Date Filter
      dateFormatOoo                                       : 'vvvv-mm-dd',

      // Filter Conditions
      andCondition                                        : 'DHE',
      orCondition                                         : 'OSE',

      // Filter Buttons
      applyFilter                                         : 'Apliko',
      resetFilter                                         : 'Ktheje nga fillimi',
      clearFilter                                         : 'Fshij',
      cancelFilter                                        : 'Anulo',

      // Filter Titles
      textFilter                                          : 'Filter Teksti',
      numberFilter                                        : 'Filter Numri',
      dateFilter                                          : 'Filter Date',
      setFilter                                           : 'Vendos Filter',

      // Side Bar
      columns                                             : 'Kolona',
      filters                                             : 'Filtra',

      // columns tool panel
      pivotMode                                           : 'Modaliteti Pivot',
      groups                                              : 'Grup Rreshtash',
      rowGroupColumnsEmptyMessage                         : 'Drag ketu per te marre te gjitha grupet e rreshtave',
      values                                              : 'Vlerat',
      valueColumnsEmptyMessage                            : 'Drag ketu per te mbledhur',
      pivots                                              : 'Etiketat e Kolonave',
      pivotColumnsEmptyMessage                            : 'Drag ketu per te vendosur etiketat e kolonave',

      // Header of the Default Group Column
      group                                               : 'Grup',

      // Row Drag
      rowDragRows                                         : 'rreshta',

      // Other
      loadingOoo                                          : 'Duke ngarkuar...',
      noRowsToShow                                        : 'Nuk ka rreshta per te shfaqur',
      enabled                                             : 'E Aktivizuar',

      // Menu
      pinColumn                                           : 'Bashkangjitni te gjitha kolonat',
      pinLeft                                             : 'Bashkangjitni majtas',
      pinRight                                            : 'Bashkangjitni djathtas',
      noPin                                               : 'Nuk ka bashkangjitje',
      valueAggregation                                    : 'Mbledhja e vleres',
      autosizeThiscolumn                                  : 'Rregulloni automatikisht madhesine e kesaj kolone',
      autosizeAllColumns                                  : 'Rregulloni automatikisht madhesite e te gjitha kolonave',
      groupBy                                             : 'Grupo nga',
      ungroupBy                                           : "Ç'grupo nga",
      addToValues                                         : 'Shto ${variable} te vlerat',
      removeFromValues                                    : 'Hiq ${variable} nga vlerat',
      addToLabels                                         : 'Shto ${variable} te etiketat',
      removeFromLabels                                    : 'Hiq ${variable} nga etiketat',
      resetColumns                                        : 'Rivendosni Kolonat',
      expandAll                                           : 'Zgjeroni te gjitha',
      collapseAll                                         : 'Mbylli te gjitha',
      copy                                                : 'Copy',
      ctrlC                                               : 'Ctrl+C',
      copyWithHeaders                                     : 'Kopjo me Headers',
      copyWithGroupHeaders                                : 'Kopjo me Grup Headers',
      paste                                               : 'Paste',
      ctrlV                                               : 'Ctrl+V',
      export                                              : 'Eksporto',
      csvExport                                           : 'CSV Export',
      excelExport                                         : 'Eksport Excel-i',

      // Enterprise Menu Aggregation and Status Bar
      sum                                                 : 'Shuma',
      min                                                 : 'Minimumi',
      max                                                 : 'Maksimumi',
      none                                                : 'Asgje',
      count                                               : 'Numero',
      avg                                                 : 'Mesatarja',
      filteredRows                                        : 'E Filtruar',
      selectedRows                                        : 'E Selektuar',
      totalRows                                           : 'Rreshta Total',
      totalAndFilteredRows                                : 'Rreshta',
      more                                                : 'Me Shume',
      to                                                  : 'te',
      of                                                  : 'e',
      page                                                : 'Faqja',
      nextPage                                            : 'Faqja Pasardhese',
      lastPage                                            : 'Faqja e fundit',
      firstPage                                           : 'Faqja e pare',
      previousPage                                        : 'Faqja paraardhese',

      // Pivoting
      pivotColumnGroupTotals                              : 'Totali',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode                              : 'Grafik Pivot & Modaliteti Pivot',
      pivotChart                                          : 'Grafik Pivot',
      chartRange                                          : 'Rrezja e Grafikut',

      columnChart                                         : 'Kolona',
      groupedColumn                                       : 'E grupuar',
      stackedColumn                                       : 'E mbledhur',
      normalizedColumn                                    : '100% e mbledhur',

      barChart                                            : 'Shirit',
      groupedBar                                          : 'I grupuar',
      stackedBar                                          : 'I mbledhur',
      normalizedBar                                       : '100% i mbledhur',

      pieChart                                            : 'Pie',
      pie                                                 : 'Pie',
      doughnut                                            : 'Doughnut',

      line                                                : 'Vije',

      xyChart                                             : 'X Y (Shperndaj)',
      scatter                                             : 'Shperndaj',
      bubble                                              : 'Flluske',

      areaChart                                           : 'Zone',
      area                                                : 'Zone',
      stackedArea                                         : 'E mbledhur',
      normalizedArea                                      : '100% e mbledhur',

      histogramChart                                      : 'Histogram',

      combinationChart                                    : 'Kombinim',
      columnLineCombo                                     : 'Kolona & Vija',
      AreaColumnCombo                                     : 'Zona & Kolona',

      // Charts
      pivotChartTitle                                     : 'Grafik Pivot',
      rangeChartTitle                                     : 'Grafik Rreze',
      settings                                            : 'Opsionet',
      data                                                : 'Data',
      format                                              : 'Format',
      categories                                          : 'Kategorite',
      defaultCategory                                     : '(Asgje)',
      series                                              : 'Seri',
      xyValues                                            : 'Vlerat X Y',
      paired                                              : 'Modalitet i ngjitur',
      axis                                                : 'Boshti',
      navigator                                           : 'Navigator',
      color                                               : 'Ngjyra',
      thickness                                           : 'Trashesia',
      xType                                               : 'Tipi X',
      automatic                                           : 'Automatike',
      category                                            : 'Kategori',
      number                                              : 'Numer',
      time                                                : 'Koha',
      autoRotate                                          : 'Auto Kthim',
      xRotation                                           : 'Kthim X',
      yRotation                                           : 'Kthim Y',
      ticks                                               : 'Ticks',
      width                                               : 'Gjeresia',
      height                                              : 'Lartesia',
      length                                              : 'Gjatesia',
      padding                                             : 'Mbushjet',
      spacing                                             : 'Hapesirat',
      chart                                               : 'Grafikat',
      title                                               : 'Titulli',
      titlePlaceholder                                    : 'Titulli i grafikut - shtyp dy here per ta edituar',
      background                                          : 'Backgroundi',
      font                                                : 'Madhesia e shrimit',
      top                                                 : 'Ne krye',
      right                                               : 'Djathtas',
      bottom                                              : 'Ne fund',
      left                                                : 'Majtas',
      labels                                              : 'Etiketat',
      size                                                : 'Madhesia',
      minSize                                             : 'Madhesia minimale',
      maxSize                                             : 'Madhesia maksimale',
      legend                                              : 'Legjenda',
      position                                            : 'Pozicioni',
      markerSize                                          : 'Madhesia e shenuesit',
      markerStroke                                        : 'Goditja e shenuesit',
      markerPadding                                       : 'Mbushja e shenuesit',
      itemSpacing                                         : 'Hapesira e artikujve',
      itemPaddingX                                        : 'Mbushja e artikujve X',
      itemPaddingY                                        : 'Mbushja e artikujve Y',
      layoutHorizontalSpacing                             : 'Hapesira Horizontale',
      layoutVerticalSpacing                               : 'Hapesira Vertikale',
      strokeWidth                                         : 'Gjeresia e goditjes',
      lineDash                                            : 'Linja e vijes',
      offset                                              : 'Offset',
      offsets                                             : 'Offsets',
      tooltips                                            : 'Tooltips',
      callout                                             : 'Callout',
      markers                                             : 'Shenuesit',
      shadow                                              : 'Hija',
      blur                                                : 'Blur',
      xOffset                                             : 'X Offset',
      yOffset                                             : 'Y Offset',
      lineWidth                                           : 'Gjeresia e vijes',
      normal                                              : 'Normale',
      bold                                                : 'I trashe',
      italic                                              : 'I pjerret',
      boldItalic                                          : 'I pjerret dhe i trashe',
      predefined                                          : 'E paradefinuar',
      fillOpacity                                         : 'Mbushni Opacitetin',
      strokeOpacity                                       : 'Opaciteti i vijes',
      histogramBinCount                                   : 'Numerimi i koshave',
      columnGroup                                         : 'Kolonat',
      barGroup                                            : 'Shirit',
      pieGroup                                            : 'Pie',
      lineGroup                                           : 'Vije',
      scatterGroup                                        : 'X Y (Shperndaj)',
      areaGroup                                           : 'Zona',
      histogramGroup                                      : 'Histogram',
      combinationGroup                                    : 'Kombinimi',
      groupedColumnTooltip                                : 'Te grupuara',
      stackedColumnTooltip                                : 'Te mbledhura',
      normalizedColumnTooltip                             : '100% te mbledhura',
      groupedBarTooltip                                   : 'Te grupuara',
      stackedBarTooltip                                   : 'Te mbledhura',
      normalizedBarTooltip                                : '100% te mbledhura',
      pieTooltip                                          : 'Pie',
      doughnutTooltip                                     : 'Doughnut',
      lineTooltip                                         : 'Vije',
      groupedAreaTooltip                                  : 'Zone',
      stackedAreaTooltip                                  : 'Te mbledhura',
      normalizedAreaTooltip                               : '100% te mbledhura',
      scatterTooltip                                      : 'Shperndaj',
      bubbleTooltip                                       : 'Flluske',
      histogramTooltip                                    : 'Histogram',
      columnLineComboTooltip                              : 'Kolene & Vije',
      areaColumnComboTooltip                              : 'Zone & Kolone',
      customComboTooltip                                  : 'Kombinim i personalizuar',
      noDataToChart                                       : 'Nuk ka te dhena per tu vendosur ne grafik.',
      pivotChartRequiresPivotMode                         : 'Grafiku Pivot kerkon modalitetin Pivot te aktivizuar.',
      chartSettingsToolbarTooltip                         : 'Menu',
      chartLinkToolbarTooltip                             : 'E linkuar te Grid',
      chartUnlinkToolbarTooltip                           : "E Ç'linkuar nga Grid",
      chartDownloadToolbarTooltip                         : 'Shkarko Grafikun',
      seriesChartType                                     : 'Tip Grafiku me seri',
      seriesType                                          : 'Tip me seri',
      secondaryAxis                                       : 'Bosht Sekondar',

      // ARIA
      ariaChecked                                         : 'E çekuar',
      ariaColumn                                          : 'Kolona',
      ariaColumnGroup                                     : 'Grup Kolonash',
      ariaColumnList                                      : 'Liste Kolonash',
      ariaColumnSelectAll                                 : 'Toggle te gjitha kolonat e zgjedhura',
      ariaDateFilterInput                                 : 'Inputi i filtrit te dates',
      ariaDefaultListName                                 : 'Lista',
      ariaFilterColumnsInput                              : 'Inputi i filtrit te kolonave',
      ariaFilterFromValue                                 : 'Filtro nga e dhena',
      ariaFilterInput                                     : 'Filtro Inputin',
      ariaFilterList                                      : 'Filtro Listen',
      ariaFilterToValue                                   : 'Filtro tek te dhenat',
      ariaFilterValue                                     : 'Filtro Vleren',
      ariaFilteringOperator                               : 'Operatori i filtrimit',
      ariaHidden                                          : 'e fshehur',
      ariaIndeterminate                                   : 'i papercaktuar',
      ariaInputEditor                                     : 'Edituesi i Inputit',
      ariaMenuColumn                                      : 'Shtyp CTRL ENTER per te hapur menune e kolonave.',
      ariaRowDeselect                                     : 'Shtyp SPACE per te di-selektuar kete rresht',
      ariaRowSelectAll                                    : 'Shtypni SPACE per te ndryshuar zgjedhjen e te gjithe rreshtave',
      ariaRowToggleSelection                              : 'Shtypni Space për të ndryshuar zgjedhjen e rreshtit',
      ariaRowSelect                                       : 'Shtypni SPACE per te zgjedhur kete rresht',
      ariaSearch                                          : 'Kerko',
      ariaSortableColumn                                  : 'Shtyp ENTER per te renditur',
      ariaToggleVisibility                                : 'Shtyp ENTER per te ndryshuar vizibilitetin',
      ariaUnchecked                                       : 'E pa çekuar',
      ariaVisible                                         : 'e dukshme',
      ariaSearchFilterValues                              : 'Filtrat e kerkimit te vlerave',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel                      : 'Grupet e rreshtave',
      ariaValuesDropZonePanelLabel                        : 'Vlerat',
      ariaPivotDropZonePanelLabel                         : 'Etiketat e kolonave',
      ariaDropZoneColumnComponentDescription              : 'Shtyp DELETE per te hequr',
      ariaDropZoneColumnValueItemDescription              :
        'Shtyp ENTER per te ndryshuar tipin e mbledhjes',
      // used for aggregate drop zone, format             : {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator         : ' e ',
      ariaDropZoneColumnComponentSortAscending            : 'rend rrites',
      ariaDropZoneColumnComponentSortDescending           : 'rend zbrites',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu                                 : 'Menuja e kolonave',
      ariaLabelCellEditor                                 : 'Edituesi i qelizave',
      ariaLabelDialog                                     : 'Dialogu',
      ariaLabelSelectField                                : 'Zgjidh fushen',
      ariaLabelTooltip                                    : 'Tooltip',
      ariaLabelContextMenu                                : 'Menuja e kontekstit',
      ariaLabelSubMenu                                    : 'SubMenu',
      ariaLabelAggregationFunction                        : 'Funksioni i mbledhjes',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator                                   : ',',
      decimalSeparator                                    : '.',
    }
  }
}


